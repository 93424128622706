import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(private globalService: GlobalService, private dataService: DataService, private message: NzMessageService,
    private fb: FormBuilder) { }
  dataResult: Faq[];
  faqGroup: FormGroup;
  editCache: { [key: string]: { visibleImage: boolean; visibleIcon: boolean; loading: boolean; edit: boolean; data: Faq; } } = {};
  loading = true;

  ngOnInit() {
    this.loading = true;
    this.dataService.getTable('faq').subscribe(
      result => {
        this.dataResult = result as Faq[];
        this.updateEditCache();
        this.loading = false;
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('GET TABLE: ', error);
        this.loading = false;
      }
    );

    this.faqGroup = this.fb.group({
      code: new FormControl('', [Validators.required]),
      question: this.fb.group({
        de: new FormControl(''),
        el: new FormControl(''),
        en: new FormControl(''),
        es: new FormControl(''),
        fr: new FormControl(''),
        hu: new FormControl(''),
        it: new FormControl(''),
        pl: new FormControl(''),
        pt: new FormControl(''),
        ro: new FormControl(''),
        zh: new FormControl(''),
        zs: new FormControl(''),
        ar: new FormControl(''),
        cs: new FormControl(''),
        sk: new FormControl(''),
        vn: new FormControl('')
      }),
      answer: this.fb.group({
        de: new FormControl(''),
        el: new FormControl(''),
        en: new FormControl(''),
        es: new FormControl(''),
        fr: new FormControl(''),
        hu: new FormControl(''),
        it: new FormControl(''),
        pl: new FormControl(''),
        pt: new FormControl(''),
        ro: new FormControl(''),
        zh: new FormControl(''),
        zs: new FormControl(''),
        ar: new FormControl(''),
        cs: new FormControl(''),
        sk: new FormControl(''),
        vn: new FormControl('')
      })
    });
  }

  /*
  * @desc build editCache as a (not deep) copy of dataResult array used for edit functions
  * @param -
  * @return void
  */
  updateEditCache(): void {
    this.dataResult.forEach(item => {
      this.editCache[item.code] = {
        visibleImage: false,
        visibleIcon: false,
        loading: false,
        edit: false,
        data: {
          code: item.code.valueOf(),
          question: this.updateLanguageCache(item.question),
          answer: this.updateLanguageCache(item.answer),
        }
      };
    });
  }

  /*
  * @desc build editCache.title as a (not deep) copy of dataResult.title array
  * @param itemTitle -> dataResult[i].title array
  * @return void
  */
  updateLanguageCache(itemTitle: Title[]) {
    const title: Title[] = [];
    itemTitle.forEach(titleItem => {
      title.push({
        key: titleItem.key.valueOf(),
        value: titleItem.value.valueOf()
      });
    });
    return title;
  }

  /*
  * @desc start edit action
  * @param code -> dataResult item's code
  * @return void
  */
  startEdit(code: string): void {
    this.editCache[code].edit = true;
  }

  /*
  * @desc cancel edit action. Reset editCache
  * @param -
  * @return void
  */
  cancelEdit() {
    this.updateEditCache();
  }

  /*
  * @desc save edit action. call dataService, find and replace edited record in the database
  * @param code -> dataResult item's code
  * @return void
  */
  async saveEdit(code: string) {
    const index = this.dataResult.findIndex(item => item.code === code);
    Object.assign(this.dataResult[index], this.editCache[code].data);
    this.editCache[code].edit = false;
    // build a question string
    let q = '{';
    for (let i = 0; i < this.dataResult[index].question.length; i++) {
      q = q + '"' + this.dataResult[index].question[i].key + '":"' +
        this.dataResult[index].question[i].value.toString() + '"';
      if (i < this.dataResult[index].question.length - 1) {
        q = q + ',';
      }
    }
    q = q + '}';

    // build a question string
    let a = '{';
    for (let i = 0; i < this.dataResult[index].answer.length; i++) {
      a = a + '"' + this.dataResult[index].answer[i].key + '":"' +
        this.dataResult[index].answer[i].value.toString() + '"';
      if (i < this.dataResult[index].answer.length - 1) {
        a = a + ',';
      }
    }
    a = a + '}';

    const checkSumKey = await this.globalService.encryptDataGlobal(code + q);
    const formData = new FormData();
    formData.append('table', 'faqs');
    formData.append('code', code);
    formData.append('question', q);
    formData.append('answer', a);
    formData.append('checkKey', checkSumKey);
    // call data service, find and REPLACE records
    this.dataService.editTable(formData).subscribe(
      result => {
        this.message.create('success', 'Record edited successfully', { nzDuration: 5000 });
        this.updateEditCache();
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('EDIT: ', error);
      }
    );
  }

  /*
    * @desc delete action. Find and delete record in the database
    * @param code -> dataResult item's code
    * @return void
    */
  async deleteRow(code: string) {
    this.dataResult = this.dataResult.filter(d => d.code !== code);

    const checkSumKey = await this.globalService.encryptDataGlobal(code + 'TblFaq');
    const formData = new FormData();
    formData.append('table', 'TblFaq');
    formData.append('code', code);
    formData.append('checkKey', checkSumKey);
    // call data service, DELETE record
    this.dataService.deleteRowTable(formData).subscribe(
      result => {
        this.message.create('success', 'Record deleted successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'An error occured', { nzDuration: 5000 });
        console.log('DELETE: ', error);
      }
    );
  }

  /*
  * @desc add action. add record in the database
  * @param -
  * @return void
  */
  async addRow() {
    // IF FORM INPUT CODE ALREADY EXISTS -> SHOW ERROR
    const codeIndex = this.dataResult.findIndex(item => item.code === this.faqGroup.value.code);
    if (codeIndex > -1) {
      this.message.create('error', 'Faq\'s code already exists', { nzDuration: 5000 });
    } else if (this.faqGroup.invalid) {
      this.message.create('error', 'All * fields are required', { nzDuration: 5000 });
    } else {
      // create a string {\n "de" : "--"\n "it" : "--"\n ....\n}
      const find = '",';
      const re = new RegExp(find, 'g');
      let q = (JSON.stringify(this.faqGroup.value.question));

      let a = (JSON.stringify(this.faqGroup.value.answer));

      const checkSumKey = await this.globalService.encryptDataGlobal(this.faqGroup.value.code + q);
      const formData = new FormData();
      formData.append('table', 'faqs');
      formData.append('code', this.faqGroup.value.code);
      formData.append('question', q);
      formData.append('answer', a);
      formData.append('checkKey', checkSumKey);

      this.dataService.addRowToTable(formData).subscribe(
        result => {
          this.message.create('success', 'Row added successfully', { nzDuration: 5000 });
          // refresh editCache and dataResult with a new row
          this.ngOnInit();
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          console.log('ADD: ', error);
        }
      );
    }
  }


}
