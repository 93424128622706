import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.scss']
})
export class FrontComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private dataService: DataService, private message: NzMessageService) { }

  lang: string;
  dataResult: Faq[];
  displayedData: any[] = [];
  loading = true;
  actives: boolean[] = [];

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['lang']) {
        this.lang = params['lang'];
      } else {
        this.router.navigateByUrl('/login');
      }
    });

    this.loading = true;
    this.dataService.getTable('faq').subscribe(
      result => {
        console.log(result);
        this.dataResult = result as Faq[];
        this.dataResult.forEach(data => {
            data.question.forEach(q => {
              if (q.key == this.lang && q.value) {
                data.answer.forEach(a => {
                  if (a.key == this.lang && a.value) {
                    this.displayedData.push({
                      question: q.value,
                      answer: a.value
                    })
                    this.actives.push(false);
                  }
                });
              }
            });
        });
        this.loading = false;
        console.log('DD', this.displayedData)
      },
      error => {
        this.message.create('error', 'An error occured', { nzDuration: 5000 });
        console.log('GET TABLE: ', error);
        this.loading = false;
      }
    );
  }


  changeActive(i) {
    this.actives[i] = !this.actives[i];
  }
}
