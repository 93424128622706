import { Injectable } from "@angular/core";
import crc32 from "crc/crc32";
import { SodiumPlus, CryptographyKey } from "sodium-plus";

@Injectable({
	providedIn: "root",
})
export class GlobalService {
	appVersion: string = "v23.04.26";
	keyBuffer: Buffer;
	imgPath: string;
	uploadURL: string;
	phpPath: string;
	productCatValues: any[] = [];
	advLanguagesCode: any[] = [];
	filterCountryCode: any[] = [];
	allLanguages: any[] = [];
	sodium: SodiumPlus;

	constructor() {
		// only for online mode
		this.phpPath = "/php";
		this.uploadURL = "/php/";
		this.imgPath = "https://app.laica.it/assets/";

		// only for developer mode
		if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
			// this.phpPath = "https://appadmin.laica.it/php";
			// this.uploadURL = "https://appadmin.laica.it/php/";
			this.phpPath = "http://localhost:3000/php";
			this.uploadURL = "http://localhost:3000/php/";
			//this.imgPath = "http://localhost:3000/assets/";
		}

		this.advLanguagesCode = [
			{ value: "", text: "ALL LANGUAGE" },
			{ value: "de", text: "German" },
			{ value: "el", text: "Greek" },
			{ value: "en", text: "English" },
			{ value: "fr", text: "French" },
			{ value: "es", text: "Spanish" },
			{ value: "hu", text: "Hungarian" },
			{ value: "it", text: "Italian" },
			{ value: "pl", text: "Polish" },
			{ value: "ro", text: "Romanian" },
			{ value: "zh", text: "Chinese" },
			{ value: "pt", text: "Portuguese" },
			{ value: "vn", text: "Vietnamese" },
		];
		this.filterCountryCode = [
			{ value: "", text: "ALL COUNTRIES" },
			{ value: "de", text: "Germany" },
			{ value: "el", text: "Greece" },
			{ value: "en", text: "England" },
			{ value: "fr", text: "France" },
			{ value: "es", text: "Spain" },
			{ value: "hu", text: "Hungary" },
			{ value: "it", text: "Italy" },
			{ value: "pl", text: "Poland" },
			{ value: "ro", text: "Romania" },
			{ value: "zh", text: "China" },
			{ value: "pt", text: "Portugal" },
			{ value: "vn", text: "Vietnam" },
		];

		this.allLanguages = [
			{ value: "de", text: "German" },
			{ value: "el", text: "Greek" },
			{ value: "en", text: "English" },
			{ value: "fr", text: "French" },
			{ value: "es", text: "Spanish" },
			{ value: "hu", text: "Hungarian" },
			{ value: "it", text: "Italian" },
			{ value: "pl", text: "Polish" },
			{ value: "ro", text: "Romanian" },
			{ value: "zh", text: "Chinese" },
			{ value: "pt", text: "Portuguese" },
			{ value: "vn", text: "Vietnamese" },
			{ value: "zs", text: "Chinese (Simplified)" },
			{ value: "ar", text: "Arabic" },
			{ value: "cs", text: "Czech" },
			{ value: "sk", text: "Slovak" },
		];
	}

	saveKey(data: string) {
		this.keyBuffer = Buffer.from(data, "utf8");
	}

	/*
	 * @desc encrypt request before a service is called
	 * @param data -> decrypted data
	 * @return encrypted data
	 */
	async encryptDataGlobal(data: string) {
		const crc32Key = crc32(data);
		const base64Key = btoa(crc32Key);

		if (!this.sodium) {
			this.sodium = await SodiumPlus.auto();
		}
		const nonce = await this.sodium.randombytes_buf(24);
		const keyPair = new CryptographyKey(this.keyBuffer);
		const sKey = await this.sodium.crypto_box_secretkey(keyPair);
		const pKey = await this.sodium.crypto_box_publickey(keyPair);
		const encrypted = await this.sodium.crypto_box(base64Key, nonce, sKey, pKey);

		return nonce.toString("hex") + encrypted.toString("hex");
	}
}
