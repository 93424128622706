import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpErrorResponse } from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { GlobalService } from "../shared/global.service";
import { promise } from "protractor";

@Injectable({
	providedIn: "root",
})
export class DataService {
	private baseUrl: string;
	private header = new HttpHeaders();

	constructor(private http: HttpClient, private global: GlobalService) {
		this.baseUrl = this.global.phpPath;
	}
	/*
	 * @desc call backend and get requested table
	 * @param request -> requested table
	 * @return Observable<Error | any>, any if table exists, error otherwise
	 */
	getTable(request: string): Observable<Error | any[]> {
		this.header.append("Content-Type", "application/json");
		return this.http.post<any[]>(this.baseUrl + "/getTable.php", request, { headers: this.header }).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.error instanceof Error) {
					// A client-side or network error occurred
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				} else {
					// A backend error occured (err.status = 500)
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				}
			})
		);
	}

	getTablePiece(request: string): Observable<Error | any[]> {
		this.header.append("Content-Type", "application/json");
		return this.http.post<any[]>(this.baseUrl + "/getTable.php", "users" + request, { headers: this.header }).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.error instanceof Error) {
					// A client-side or network error occurred
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				} else {
					// A backend error occured (err.status = 500)
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				}
			})
		);
	}

	/*
	 * @desc call backend and edit requested table's row
	 * @param formData -> contains request
	 * @return Observable<Error | any>, any if it works, error otherwise
	 */
	editTable(formData: FormData): Observable<Error | any> {
		this.header.append("Content-Type", "application/json");
		return this.http.post<any>(this.baseUrl + "/editRow.php", formData, { headers: this.header }).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.error instanceof Error) {
					// A client-side or network error occurred
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				} else {
					// A backend error occured (err.status = 500)
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				}
			})
		);
	}

	/*
	 * @desc call backend and edit requested table's row's status
	 * @param formData -> contains request
	 * @return Observable<Error | any>, any if it works, error otherwise
	 */
	editStatus(formData: FormData): Observable<Error | any> {
		this.header.append("Content-Type", "application/json");
		return this.http.post<any>(this.baseUrl + "/changeStatus.php", formData, { headers: this.header }).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.error instanceof Error) {
					// A client-side or network error occurred
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				} else {
					// A backend error occured (err.status = 500)
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				}
			})
		);
	}

	/*
	 * @desc call backend and edit requested table's row's status
	 * @param formData -> contains request
	 * @return Observable<Error | any>, any if it works, error otherwise
	 */
	editVerified(formData: FormData): Observable<Error | any> {
		this.header.append("Content-Type", "application/json");
		return this.http.post<any>(this.baseUrl + "/changeVerified.php", formData, { headers: this.header }).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.error instanceof Error) {
					// A client-side or network error occurred
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				} else {
					// A backend error occured (err.status = 500)
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				}
			})
		);
	}

	/*
	 * @desc call backend and edit requested table's row's files-name
	 * @param formData -> contains request
	 * @return Observable<Error | any>, any if it works, error otherwise
	 */
	uploadFiles(formData: FormData): Observable<Error | any> {
		this.header.append("Content-Type", "application/json");
		return this.http.post<any>(this.baseUrl + "/uploadFile.php", formData, { headers: this.header }).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.error instanceof Error) {
					// A client-side or network error occurred
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				} else {
					// A backend error occured (err.status = 500)
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				}
			})
		);
	}

	/*
	 * @desc call backend and delete requested table's row
	 * @param formData -> contains request
	 * @return Observable<Error | any>, any if it works, error otherwise
	 */
	deleteRowTable(formData: FormData): Observable<Error | any> {
		this.header.append("Content-Type", "application/json");
		return this.http.post<any>(this.baseUrl + "/deleteRow.php", formData, { headers: this.header }).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.error instanceof Error) {
					// A client-side or network error occurred
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				} else {
					// A backend error occured (err.status = 500)
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				}
			})
		);
	}

	/*
	 * @desc call backend and add row in requested table
	 * @param formData -> contains request
	 * @return Observable<Error | any>, any if it works, error otherwise
	 */
	addRowToTable(formData: FormData): Observable<Error | any> {
		this.header.append("Content-Type", "application/json");
		return this.http.post<any>(this.baseUrl + "/addRow.php", formData, { headers: this.header }).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.error instanceof Error) {
					// A client-side or network error occurred
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				} else {
					// A backend error occured (err.status = 500)
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				}
			})
		);
	}

	/*
	 * @desc call backend and get categories title json
	 * @param formData -> contains request
	 * @return Observable<Error | any>, any if it works, error otherwise
	 */
	getCategoriesTitle(): Observable<Error | any> {
		this.header.append("Content-Type", "application/json");
		return this.http.get<any>(this.baseUrl + "/getCategoriesTitle.php", { headers: this.header }).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.error instanceof Error) {
					// A client-side or network error occurred
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				} else {
					// A backend error occured (err.status = 500)
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				}
			})
		);
	}

	/*
	 * @desc call backend and get key storaged in it
	 * @param -
	 * @return Observable<Error | any>, any if it works, error otherwise
	 */
	getKey(): Observable<Error | any> {
		this.header.append("Content-Type", "application/json");
		return this.http.get<any>(this.baseUrl + "/getKey.php", { headers: this.header }).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.error instanceof Error) {
					// A client-side or network error occurred
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				} else {
					// A backend error occured (err.status = 500)
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				}
			})
		);
	}
}
