import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  appVersion: string;
  isCollapsed = false;
  constructor(private auth: AuthService, private dataService: DataService,
              private global: GlobalService, private message: NzMessageService) { }

  ngOnInit() {
    this.appVersion = this.global.appVersion;
    this.dataService.getKey().subscribe(
    result => {
      for (let i = 0; i < result.length; i++) {
        this.global.saveKey(result[i].key);
      }
    },
    error => {
      this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
      console.log('GET KEY: ', error);
    });
  }

  /*
  * @desc logout from application
  * @return void
  */
  onSubmitLogout() {
    this.auth.logout();
  }

}
