import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-livings-notifications',
  templateUrl: './livings-notifications.component.html',
  styleUrls: ['./livings-notifications.component.scss']
})
export class LivingsNotificationsComponent implements OnInit {
  sortName: string | null = null;
  sortValue: string | null = null;
  dataResult: Notification[];
  sortedData: Notification[];
  listOfSearchName: string[] = [];
  loading = true;
  constructor(private dataService: DataService, private message: NzMessageService) { }
  /*
  * Get table content on Component init
  */
  ngOnInit() {
    this.loading = true;
    this.dataService.getTable('livingsnotifications').subscribe(
      result => {
        this.dataResult = <Notification[]> result;
        this.sortedData = [
          ...this.dataResult
        ];
        this.loading = false;
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        this.loading = false;
        console.log('GET TABLE: ', error);
      }
    );
  }

  /*
  * @desc assign to this.sortName and this.sortValue the inputed name and value
  * @param sort -> contains selected key and value
  * @return void
  */
  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search();
  }

  /*
  * @desc sort and filt displayed data from name and value
  * @param -
  * @return void
  */
  search(): void {
    const filterFunc = (item: Notification) =>
      (this.listOfSearchName.length ? this.listOfSearchName.some(email => item.email.indexOf(email) !== -1) : true);
    const data = this.sortedData.filter(item => filterFunc(item));
    /* sort data */
    if (this.sortName && this.sortValue) {
      this.dataResult = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
      );
    } else {
      this.dataResult = data;
    }
  }

}
