import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import {sha256} from '../../../../node_modules/js-sha256';
import { GlobalService } from 'src/app/shared/global.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  appVersion: string;
  password: string;
  username: string;
  showError: boolean;
  constructor(private router: Router, private auth: AuthService, private globalService: GlobalService) { }

  ngOnInit() {
    this.appVersion = this.globalService.appVersion;
    this.password = '';
    this.showError = false;
  }

  /*
  * @desc check if inputed psw is valid
  * @return void
  */
  onSubmit() {
    const formData = new FormData();
    formData.append('login', sha256(this.password));
    this.auth.checkLogin(formData).subscribe(
      result => {
        this.auth.sendToken('1');
        this.router.navigateByUrl('/admin');
        this.globalService.saveKey(sha256(this.password));
      },
      error => {
        this.showError = true;
        console.log('LOGIN: ', error);
      }
    );
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }
}
