import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NzMessageService, UploadFile, UploadXHRArgs } from 'ng-zorro-antd';
import { HttpRequest, HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { GlobalService } from 'src/app/shared/global.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  protocolValues: string[] = ['a', 'b', 'c', 'd', 'e'];
  requiredValues: string[] = ['target', 'memory'];
  categoryValues: any[] = [];
  dataResult: Product[];
  editCache: { [key: string]: { visibleIcon: boolean; visibleImage: boolean; visibleLotto: boolean;
                                loading: boolean; edit: boolean; data: Product; } } = {};
  imgPath = this.globalService.imgPath + 'products/'; // same movie
  uploadURL = this.globalService.uploadURL + 'changeProductImages.php';
  pdfPath = this.globalService.imgPath + 'products/';
  uploadPdfURL = this.globalService.uploadURL + 'changeProductImages.php';
  productGroup: FormGroup;
  loading = true;
  showForm = false;

  constructor(private globalService: GlobalService, private dataService: DataService, private message: NzMessageService,
              private http: HttpClient, private fb: FormBuilder) { }
  /*
  * Get table content on Component init
  */
  ngOnInit() {
    this.loading = true;
    this.dataService.getTable('products').subscribe(
      result => {
        this.dataResult = <Product[]> result;
        this.updateEditCache();
        this.loading = false;
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('GET TABLE: ', error);
        this.loading = false;
      }
    );
    this.productGroup = this.fb.group({
      code: new FormControl('', [Validators.required]),
      title: new FormControl('', [Validators.required]),
      catCode: new FormControl(null, [Validators.required]),
      comType: new FormControl(null, [Validators.required]),
      required: new FormControl(null),
    });

    this.dataService.getCategoriesTitle().subscribe(
      result => {
        for (let i = 0; i < result.length; i++) {
          this.categoryValues.push({'value' : result[i].catCode, 'text' : result[i].catTitle});
        }
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('GET CATEGORIES TITLE: ', error);
      }
    );
  }

  /*
  * @desc build editCache as a (not deep) copy of dataResult array used for edit functions
  * @param -
  * @return void
  */
  updateEditCache(): void {
    this.dataResult.forEach(item => {
      this.editCache[item.code] = {
        visibleImage: false,
        visibleIcon: false,
        visibleLotto: false,
        loading: false,
        edit: false,
        data: { ...item }
      };
    });
  }

  /*
  * @desc start edit action
  * @param code -> dataResult item's code
  * @return void
  */
  startEdit(code: string): void {
    this.editCache[code].edit = true;
  }

  /*
  * @desc cancel edit action. Reset editCache
  * @param -
  * @return void
  */
  cancelEdit(code: string): void {
    const index = this.dataResult.findIndex(item => item.code === code);
    this.editCache[code] = {
      visibleImage: false,
      visibleIcon: false,
      visibleLotto: false,
      data: { ...this.dataResult[index] },
      loading: false,
      edit: false
    };
  }

  /*
  * @desc save edit action. call dataService, find and replace edited record in the database
  * @param code -> dataResult item's code
  * @return void
  */
  async saveEdit(code: string) {
    const index = this.dataResult.findIndex(item => item.code === code);
    Object.assign(this.dataResult[index], this.editCache[code].data);
    this.editCache[code].edit = false;

    const checkSumKey = await this.globalService.encryptDataGlobal(code + this.dataResult[index].title +
      this.dataResult[index].catCode + this.dataResult[index].comType +
      this.dataResult[index].required);
    const formData = new FormData();
    formData.append('table', 'products');
    formData.append('code', code);
    formData.append('title', this.dataResult[index].title);
    formData.append('catCode', this.dataResult[index].catCode);
    formData.append('comType', this.dataResult[index].comType);
    formData.append('linkTutorial', this.dataResult[index].linkTutorial);
    formData.append('required', this.dataResult[index].required);
    formData.append('checkKey', checkSumKey);
    // call data service, find and REPLACE records
    this.dataService.editTable(formData).subscribe(
      result => {
        this.message.create('success', 'Record edited successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('EDIT: ', error);
      }
    );
  }

  /*
  * @desc delete action. Find and delete record in the database
  * @param code -> dataResult item's code
  * @return void
  */
  async deleteRow(code: string) {
    this.dataResult = this.dataResult.filter(d => d.code !== code);

    const checkSumKey = await this.globalService.encryptDataGlobal(code + 'TblProducts');
    const formData = new FormData();
    formData.append('table', 'TblProducts');
    formData.append('code', code);
    formData.append('checkKey', checkSumKey);
    // call data service, DELETE record
    this.dataService.deleteRowTable(formData).subscribe(
      result => {
        this.message.create('success', 'Record deleted successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('DELETE: ', error);
      }
    );
  }

  /*
  * @desc add action. add record in the database
  * @param -
  * @return void
  */
  async addRow() {
    // IF FORM INPUT CODE ALREADY EXISTS -> SHOW ERROR
    const codeIndex = this.dataResult.findIndex(item => item.code === this.productGroup.value.code);
    if (codeIndex > -1) {
      this.message.create('error', 'Product\'s code already exists', { nzDuration: 5000 });
    } else if (this.productGroup.invalid) {
      this.message.create('error', 'All * fields are required', { nzDuration: 5000 });
    } else {
      const checkSumKey = await this.globalService.encryptDataGlobal(this.productGroup.value.code +
        this.productGroup.value.title + this.productGroup.value.catCode + this.productGroup.value.comType +
        this.productGroup.value.required);
      const formData = new FormData();
      formData.append('table', 'products');
      formData.append('code', this.productGroup.value.code);
      formData.append('title', this.productGroup.value.title);
      formData.append('catCode', this.productGroup.value.catCode);
      formData.append('comType', this.productGroup.value.comType);
      formData.append('required', this.productGroup.value.required);
      formData.append('checkKey', checkSumKey);
      // call data service, ADD record
      this.dataService.addRowToTable(formData).subscribe(
        result => {
          this.message.create('success', 'Row added successfully', { nzDuration: 5000 });
          // refresh editCache and dataResult with a new row
          this.ngOnInit();
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          console.log('ADD: ', error);
        }
      );
    }
  }

  /*
  * @desc change state action. Find and change record's status in the database
  * @param code -> dataResult item's code
  * @return void
  */
  async onClickSwitch(code: string) {
    const index = this.dataResult.findIndex(item => item.code === code);
    this.dataResult[index].status = !this.dataResult[index].status;
    this.editCache[code].data.status = !this.editCache[code].data.status;

    const checkSumKey = await this.globalService.encryptDataGlobal(code + 'TblProducts');
    const formData = new FormData();
    formData.append('table', 'TblProducts');
    formData.append('code', code);
    formData.append('checkKey', checkSumKey);
    this.dataService.editStatus(formData).subscribe(
      result => {
        this.message.create('success', 'Status changed successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('CHANGE SWITCH: ', error);
      }
    );
  }

  /*
  * @desc change icon action. save icon as "dataResult.code_thb" in this.imgPath
  * @param item -> uploaded icon name
  * @return void
  */
  customIconReq = (item: UploadXHRArgs) => {
    // Create a FormData here to store files and other parameters.
    const formData = new FormData();
    formData.append('file', item.file as any);
    formData.append('field', '\_thb');
    formData.append('filename', item.name);
    formData.append('ext', item.file.name.substr(item.file.name.lastIndexOf('.')));
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });
    // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      err => {
        item.onError!(err, item.file!);
      }
    );
  }

  /*
  * @desc change image action. save image as "dataResult.code" in this.imgPath
  * @param item -> uploaded icon name
  * @return void
  */
  customReq = (item: UploadXHRArgs) => {
    // Create a FormData here to store files and other parameters.
    const formData = new FormData();
    formData.append('file', item.file as any);
    formData.append('field', '');
    formData.append('filename', item.name);
    formData.append('ext', item.file.name.substr(item.file.name.lastIndexOf('.')));
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });
    // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      err => {
        item.onError!(err, item.file!);
      }
    );
  }

  /*
  * @desc change lotto_image action. save lotto_image as "dataResult.code_label" in this.imgPath
  * @param item -> uploaded icon name
  * @return void
  */
  customLottoReq = (item: UploadXHRArgs) => {
    // Create a FormData here to store files and other parameters.
    const formData = new FormData();
    formData.append('file', item.file as any);
    formData.append('field', '\_label');
    formData.append('filename', item.name);
    formData.append('ext', item.file.name.substr(item.file.name.lastIndexOf('.')));
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });
    // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      err => {
        item.onError!(err, item.file!);
      }
    );
  }

  /*
  * @desc edit/add image and icon name action. find and add/edit image and icon in database
  * @param info -> uploaded file;
  * @param code -> dataResult.code;
  * @param field -> "image" || "icon" || "image_lotto";
  * @return void
  */
  async handleChange(info: { file: UploadFile }, code: string, field: string) {
    switch (info.file.status) {
      case 'uploading':
        this.editCache[code].loading = true;
        break;
      case 'done':
        const data: Product = this.editCache[code].data;
        const fileExtension = info.file.name.substr(info.file.name.lastIndexOf('.'));
        const index = this.dataResult.findIndex(item => item.code === code);
        // ****************************************ICON UPDATING
        if (field === 'icon') {
          // CUSTOMIZED ICON NAME
          data.icon = data.code + '\_thb' + fileExtension;
          const checkSumKey = await this.globalService.encryptDataGlobal(data.code + field + data.icon);
          // ENTER HERE WHEN:
          // 1- data.icon doesn't exists
          // 2- upload a new icon with different extension
          if (!this.dataResult[index].icon.includes(data.icon)) {
            const formData = new FormData();
            formData.append('table', 'TblProducts');
            formData.append('code', data.code);
            formData.append('file', data.icon as any);
            formData.append('field', 'icon');
            formData.append('checkKey', checkSumKey);

            this.dataService.uploadFiles(formData).subscribe(
              result => {
                this.message.create('success', 'Icon added successfully', { nzDuration: 5000 });
              },
              error => {
                this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
                console.log('UPLOAD FILE: ', error);
              }
            );
          } else {
            this.message.create('success', 'Icon changed successfully', { nzDuration: 5000 });
            // REFRESH URL
            data.icon += '?random+\=' + Math.random();
            this.editCache[code].loading = true;
          }
        } else if (field === 'image') {
          // CUSTOMIZED IMAGE NAME
          data.image = data.code + fileExtension;
          const checkSumKey = await this.globalService.encryptDataGlobal(data.code + field + data.image);
          // ENTER HERE WHEN:
          // 1- data.image doesn't exists
          // 2- upload a new image with different extension
          if (!this.dataResult[index].image.includes(data.image)) {
            const formData = new FormData();
            formData.append('table', 'TblProducts');
            formData.append('code', data.code);
            formData.append('file', data.image as any);
            formData.append('field', 'image');
            formData.append('checkKey', checkSumKey);

            this.dataService.uploadFiles(formData).subscribe(
              result => {
                this.message.create('success', 'Image added successfully', { nzDuration: 5000 });
                this.updateEditCache();
              },
              error => {
                this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
                console.log('UPLOAD FILE: ', error);
              }
            );
          } else {
            this.message.create('success', 'Image changed successfully', { nzDuration: 5000 });
            // REFRESH URL
            data.image += '?random+\=' + Math.random();
            this.editCache[code].loading = true;
          }
        } else if (field === 'image_lotto') {
          data.imageLotto = data.code + '\_label' + fileExtension;
          const checkSumKey = await this.globalService.encryptDataGlobal(data.code + field + data.imageLotto);
          // ENTER HERE WHEN:
          // 1- data.imageLotto doesn't exists
          // 2- upload a new lottoimage with different extension
          if (!this.dataResult[index].imageLotto.includes(data.imageLotto)) {
            const formData = new FormData();
            formData.append('table', 'TblProducts');
            formData.append('code', data.code);
            formData.append('file', data.imageLotto as any);
            formData.append('field', 'image_lotto');
            formData.append('checkKey', checkSumKey);

            this.dataService.uploadFiles(formData).subscribe(
              result => {
                this.message.create('success', 'Image added successfully', { nzDuration: 5000 });
                this.updateEditCache();
              },
              error => {
                this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
                console.log('UPLOAD FILE: ', error);
              }
            );
          } else {
            this.message.create('success', 'Lotto image changed successfully', { nzDuration: 5000 });
            // REFRESH URL
            data.imageLotto += '?random+\=' + Math.random();
            this.editCache[code].loading = true;
          }

        }
        // ASSIGN EDITED PICTURE
        Object.assign(this.dataResult[index], this.editCache[code].data);
        this.editCache[code].loading = false;
        break;
      case 'error':
        this.message.create('error', 'An error occured', { nzDuration: 5000 });
        console.log('HANDLE CHANGE: ', info.file.status, info.file, code);
        break;
    }
  }

  /*
  * @desc check if uploaded image is valid before insert into database and save
  * @param file -> uploaded image
  * @return boolean, true if valid, false otherwise
  */
  beforeUpload = (file: UploadFile) => {
    return new Observable((observer: Observer<boolean>) => {
      // CHECK EXTENSION TYPES
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isJPG && !isPNG) {
        this.message.create('error', 'Only .png and .jpg files are supported', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      // CHECK SIZE file max size -> 2MB
      const isLt2M = file.size < 2097152;
      if (!isLt2M) {
        this.message.create('error', 'File size must be under 2MB', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      observer.next((isJPG || isPNG) && isLt2M);
      observer.complete();
    });
  }

  /*
  * @desc check if uploaded icon is valid before insert into database and save
  * @param file -> uploaded icon
  * @return boolean, true if valid, false otherwise
  */
  beforeUploadIcon = (file: UploadFile) => {
    return new Observable((observer: Observer<boolean>) => {
      // CHECK EXTENSION TYPES
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isJPG && !isPNG) {
        this.message.create('error', 'Only .png and .jpg files are supported', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      // CHECK SIZE file max size -> 2MB
      const isLt2M = file.size < 2097152;
      if (!isLt2M) {
        this.message.create('error', 'File size must be under 2MB', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      // CHECK DIMENSION
      this.checkIconDimension(file, 512, 512).then(dimensionRes => {
        if (!dimensionRes) {
          this.message.create('error', 'File dimension must be 512x512', { nzDuration: 5000 });
          observer.complete();
          return;
        }
        observer.next((isJPG || isPNG) && isLt2M && dimensionRes);
        observer.complete();
      });
    });
  }
  // CHECK UPLOAD DIMENSION
  checkIconDimension(file: UploadFile, maxW: number, maxH: number): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src!);
        resolve(width === maxW && height === maxH);
      };
    });
  }

  /*
  * @desc check if uploaded pdf is valid before insert into database and save
  * @param file -> uploaded pdf
  * @return boolean, true if valid, false otherwise
  */
  beforeUploadPdf = (file: UploadFile): boolean => {
    if (file.type !== 'application/pdf') {
      this.message.create('error', 'Only .pdf files are supported', { nzDuration: 5000 });
      return false;
    }
    // file max size -> 10MB
    if (file.size > 104885760) {
      this.message.create('error', 'File size must be under 10MB', { nzDuration: 5000 });
      return false;
    }
    return true;
  }

  /*
  * @desc edit/add pdf name action. find and add/edit pdf in database
  * @param info -> uploaded file;
  * @param code -> dataResult.code;
  * @return void
  */
  // ****************************************PDF DOCUMENT UPDATING
  async handlePdfChange(info: { file: UploadFile }, code: string) {
    switch (info.file.status) {
      case 'uploading':
        this.editCache[code].loading = true;
        break;
      case 'done':
        const data: Product = this.editCache[code].data;
        // ****************************************ICON UPDATING
        if (typeof data.linkDoc === undefined || data.linkDoc === '' || data.linkDoc === null) {
          // CUSTOMIZED DOC NAME
          data.linkDoc = data.code + '.pdf'; // only .pdf
          const checkSumKey = await this.globalService.encryptDataGlobal(data.code + 'link_doc' + data.linkDoc);
          const formData = new FormData();
          formData.append('table', 'TblProducts');
          formData.append('code', data.code);
          formData.append('file', data.linkDoc as any);
          formData.append('field', 'link_doc');
          formData.append('checkKey', checkSumKey);

          this.dataService.uploadFiles(formData).subscribe(
            result => {
              this.message.create('success', 'Document added successfully', { nzDuration: 5000 });
            },
            error => {
              this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
              console.log('UPLOAD PDF: ' + error);
            }
          );
        } else {
          this.message.create('success', 'Document changed successfully', { nzDuration: 5000 });
          // REFRESH URL
          this.editCache[code].loading = true;
        }
        // ASSIGN EDITED DOC
        const index = this.dataResult.findIndex(item => item.code === code);
        Object.assign(this.dataResult[index], this.editCache[code].data);
        this.editCache[code].loading = false;
        break;
      case 'error':
        this.message.create('error', 'An error occured', { nzDuration: 5000 });
        console.log('HANDLE CHANGE', info.file.status, info.file, code);
        break;
    }
  }

  /*
  * @desc check if uploaded mp4 is valid before insert into database and save
  * @param file -> uploaded mp4
  * @return boolean, true if valid, false otherwise
  */
  beforeUploadMp4 = (file: UploadFile): boolean => {
    if (file.type !== 'video/mp4') {
      this.message.create('error', 'Only .mp4 files are supported', { nzDuration: 5000 });
      return false;
    }
    // file max size -> 2MB
    if (file.size > 2097152) {
      this.message.create('error', 'File size must be under 2MB', { nzDuration: 5000 });
      return false;
    }
    return true;
  }

  /*
  * @desc edit/add video name action. find and add/edit video in database
  * @param info -> uploaded file;
  * @param code -> dataResult.code;
  * @return void
  */
  async handleMp4Change(info: { file: UploadFile }, code: string) {
    switch (info.file.status) {
      case 'uploading':
        this.editCache[code].loading = true;
        break;
      case 'done':
        const data: Product = this.editCache[code].data;
        // ****************************************ICON UPDATING
        if (typeof data.movie === undefined || data.movie === '' || data.movie === null) {
          // CUSTOMIZED DOC NAME
          data.movie = data.code + '.mp4'; // only .mp4
          const checkSumKey = await this.globalService.encryptDataGlobal(data.code + 'movie' + data.movie);

          const formData = new FormData();
          formData.append('table', 'TblProducts');
          formData.append('code', data.code);
          formData.append('file', data.movie as any);
          formData.append('field', 'movie');
          formData.append('checkKey', checkSumKey);
          this.dataService.uploadFiles(formData).subscribe(
            result => {
              this.message.create('success', 'Document added successfully', { nzDuration: 5000 });
            },
            error => {
              this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
              console.log('UPLOAD MP4: ', error);
            }
          );
        } else {
          this.message.create('success', 'Document changed successfully', { nzDuration: 5000 });
          // REFRESH URL
          this.editCache[code].loading = true;
        }
        // ASSIGN EDITED DOC
        const index = this.dataResult.findIndex(item => item.code === code);
        Object.assign(this.dataResult[index], this.editCache[code].data);
        this.editCache[code].loading = false;
        break;
      case 'error':
        console.log('HANDLE CHANGE: ', info.file.status, info.file, code);
        this.message.create('error', 'An error occured', { nzDuration: 5000 });
        break;
    }
  }
}
