export const globalNations = [
    { value: '0', group: 'Africa', text: 'Algeria', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '1', group: 'Africa', text: 'Angola', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '2', group: 'Africa', text: 'Benin', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '3', group: 'Africa', text: 'Botswana', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '4', group: 'Africa', text: 'Burkina', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '5', group: 'Africa', text: 'Burundi', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '6', group: 'Africa', text: 'Cameroon', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '7', group: 'Africa', text: 'Cape Verde', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '8', group: 'Africa', text: 'Central African Republic', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '9', group: 'Africa', text: 'Chad', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '10', group: 'Africa', text: 'Comoros', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '11', group: 'Africa', text: 'Congo, Democratic Republic of', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '12', group: 'Africa', text: 'Djibouti', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '13', group: 'Africa', text: 'Egypt', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '14', group: 'Africa', text: 'Equatorial Guinea', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '15', group: 'Africa', text: 'Eritrea', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '16', group: 'Africa', text: 'Ethiopia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '17', group: 'Africa', text: 'Gabon', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '18', group: 'Africa', text: 'Gambia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '19', group: 'Africa', text: 'Ghana', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '20', group: 'Africa', text: 'Guinea', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '21', group: 'Africa', text: 'Guinea-Bissau', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '22', group: 'Africa', text: 'Ivory Coast', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '23', group: 'Africa', text: 'Kenya', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '24', group: 'Africa', text: 'Lesotho', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '25', group: 'Africa', text: 'Liberia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '26', group: 'Africa', text: 'Libya', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '27', group: 'Africa', text: 'Madagascar', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '28', group: 'Africa', text: 'Malawi', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '29', group: 'Africa', text: 'Mali', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '30', group: 'Africa', text: 'Mauritania', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '31', group: 'Africa', text: 'Mauritius', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '32', group: 'Africa', text: 'Morocco', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '33', group: 'Africa', text: 'Mozambique', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '34', group: 'Africa', text: 'Namibia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '35', group: 'Africa', text: 'Niger', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '36', group: 'Africa', text: 'Nigeria', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '37', group: 'Africa', text: 'Rwanda', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '38', group: 'Africa', text: 'Sao Tome and Principe', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '39', group: 'Africa', text: 'Senegal', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '40', group: 'Africa', text: 'Seychelles', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '41', group: 'Africa', text: 'Sierra Leone', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '42', group: 'Africa', text: 'Somalia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '43', group: 'Africa', text: 'South Africa', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '44', group: 'Africa', text: 'South Sudan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '45', group: 'Africa', text: 'Sudan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '46', group: 'Africa', text: 'Swaziland', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '47', group: 'Africa', text: 'Tanzania', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '48', group: 'Africa', text: 'Togo', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '49', group: 'Africa', text: 'Tunisia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '50', group: 'Africa', text: 'Uganda', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '51', group: 'Africa', text: 'Zambia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '52', group: 'Africa', text: 'Zimbabwe', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - HH:mm:ss'},
    { value: '53', group: 'America', text: 'Antigua and Barbuda', code: 'en', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '54', group: 'America', text: 'Argentina', code: 'en', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '55', group: 'America', text: 'Bahamas', code: 'en', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '56', group: 'America', text: 'Barbados', code: 'en', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '57', group: 'America', text: 'Belize', code: 'en', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '58', group: 'America', text: 'Bolivia', code: 'en', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '59', group: 'America', text: 'Brazil', code: 'pt', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '60', group: 'America', text: 'Canada', code: 'fr', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '61', group: 'America', text: 'Chile', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '62', group: 'America', text: 'Colombia', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '63', group: 'America', text: 'Costa Rica', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '64', group: 'America', text: 'Cuba', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '65', group: 'America', text: 'Dominica', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '66', group: 'America', text: 'Dominican Republic', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '67', group: 'America', text: 'Ecuador', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '68', group: 'America', text: 'El Salvador', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '69', group: 'America', text: 'Grenada', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '70', group: 'America', text: 'Guatemala', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '71', group: 'America', text: 'Guyana', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '72', group: 'America', text: 'Haiti', code: 'fr', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '73', group: 'America', text: 'Honduras', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '74', group: 'America', text: 'Jamaica', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '75', group: 'America', text: 'Mexico', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '76', group: 'America', text: 'Nicaragua', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '77', group: 'America', text: 'Panama', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '78', group: 'America', text: 'Paraguay', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '79', group: 'America', text: 'Peru', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '80', group: 'America', text: 'Saint Kitts and Nevis', code: 'en', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '81', group: 'America', text: 'Saint Lucia', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '82', group: 'America', text: 'Saint Vincent and the Grenadines', code: 'en', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '83', group: 'America', text: 'Suriname', code: 'en', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '84', group: 'America', text: 'Trinidad and Tobago', code: 'en', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '85', group: 'America', text: 'United States', code: 'en', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '86', group: 'America', text: 'Uruguay', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '87', group: 'America', text: 'Venezuela', code: 'es', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '88', group: 'Asia', text: 'Afghanistan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '89', group: 'Asia', text: 'Bahrain', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '90', group: 'Asia', text: 'Bangladesh', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '91', group: 'Asia', text: 'Bhutan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '92', group: 'Asia', text: 'Brunei', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '93', group: 'Asia', text: 'Burma (Myanmar)', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '94', group: 'Asia', text: 'Cambodia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '95', group: 'Asia', text: 'China', code: 'zh', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '96', group: 'Asia', text: 'East Timor', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '97', group: 'Asia', text: 'India', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '98', group: 'Asia', text: 'Indonesia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '99', group: 'Asia', text: 'Iran', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '100', group: 'Asia', text: 'Iraq', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '101', group: 'Asia', text: 'Israel', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '102', group: 'Asia', text: 'Japan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '103', group: 'Asia', text: 'Jordan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '104', group: 'Asia', text: 'Kazakhstan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '105', group: 'Asia', text: 'Korea, North', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '106', group: 'Asia', text: 'Korea, South', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '107', group: 'Asia', text: 'Kuwait', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '108', group: 'Asia', text: 'Kyrgyzstan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '109', group: 'Asia', text: 'Laos', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '110', group: 'Asia', text: 'Lebanon', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '111', group: 'Asia', text: 'Malaysia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '112', group: 'Asia', text: 'Maldives', code: 'fr', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '113', group: 'Asia', text: 'Mongolia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '114', group: 'Asia', text: 'Nepal', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '115', group: 'Asia', text: 'Oman', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '116', group: 'Asia', text: 'Pakistan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '117', group: 'Asia', text: 'Philippines', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '118', group: 'Asia', text: 'Qatar', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '119', group: 'Asia', text: 'Russian Federation', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '120', group: 'Asia', text: 'Saudi Arabia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '121', group: 'Asia', text: 'Singapore', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '122', group: 'Asia', text: 'Sri Lanka', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '123', group: 'Asia', text: 'Syria', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '124', group: 'Asia', text: 'Tajikistan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '125', group: 'Asia', text: 'Thailand', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '126', group: 'Asia', text: 'Turkey', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '127', group: 'Asia', text: 'Turkmenistan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '128', group: 'Asia', text: 'United Arab Emirates', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '129', group: 'Asia', text: 'Uzbekistan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '130', group: 'Asia', text: 'Vietnam', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '131', group: 'Asia', text: 'Yemen', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '132', group: 'Europe', text: 'Albania', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '133', group: 'Europe', text: 'Andorra', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '134', group: 'Europe', text: 'Armenia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '135', group: 'Europe', text: 'Austria', code: 'de', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '136', group: 'Europe', text: 'Azerbaijan', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '137', group: 'Europe', text: 'Belarus', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '138', group: 'Europe', text: 'Belgium', code: 'fr', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '139', group: 'Europe', text: 'Bosnia and Herzegovina', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '140', group: 'Europe', text: 'Bulgaria', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '141', group: 'Europe', text: 'Croatia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '142', group: 'Europe', text: 'Cyprus', code: 'el', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '143', group: 'Europe', text: 'Czech Republic', code: 'cs', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '144', group: 'Europe', text: 'Denmark', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '145', group: 'Europe', text: 'Estonia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '146', group: 'Europe', text: 'Finland', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '147', group: 'Europe', text: 'France', code: 'fr', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '148', group: 'Europe', text: 'Georgia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '149', group: 'Europe', text: 'Germany', code: 'de', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '150', group: 'Europe', text: 'Greece', code: 'el', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '151', group: 'Europe', text: 'Hungary', code: 'hu', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '152', group: 'Europe', text: 'Iceland', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '153', group: 'Europe', text: 'Ireland', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '154', group: 'Europe', text: 'Italy', code: 'it', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '155', group: 'Europe', text: 'Latvia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '156', group: 'Europe', text: 'Liechtenstein', code: 'de', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '157', group: 'Europe', text: 'Lithuania', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '158', group: 'Europe', text: 'Luxembourg', code: 'fr', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '159', group: 'Europe', text: 'Macedonia', code: 'el', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '160', group: 'Europe', text: 'Malta', code: 'el', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '161', group: 'Europe', text: 'Moldova', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '162', group: 'Europe', text: 'Monaco', code: 'fr', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '163', group: 'Europe', text: 'Montenegro', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '164', group: 'Europe', text: 'Netherlands', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '165', group: 'Europe', text: 'Norway', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '166', group: 'Europe', text: 'Poland', code: 'pl', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '167', group: 'Europe', text: 'Portugal', code: 'pt', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '168', group: 'Europe', text: 'Romania', code: 'ro', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '169', group: 'Europe', text: 'San Marino', code: 'it', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '170', group: 'Europe', text: 'Serbia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '171', group: 'Europe', text: 'Slovakia', code: 'sk', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '172', group: 'Europe', text: 'Slovenia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '173', group: 'Europe', text: 'Spain', code: 'es', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '174', group: 'Europe', text: 'Sweden', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '175', group: 'Europe', text: 'Switzerland', code: 'de', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '176', group: 'Europe', text: 'Ukraine', code: 'en', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '177', group: 'Europe', text: 'United Kingdom', code: 'en', unitH: 'in', unitW: 'lb', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '178', group: 'Europe', text: 'Vatican City', code: 'it', unitH: 'cm', unitW: 'kg', date: 'dd/MM/yyyy - HH:mm:ss'},
    { value: '179', group: 'Oceania', text: 'Australia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '180', group: 'Oceania', text: 'Fiji', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '181', group: 'Oceania', text: 'Kiribati', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '182', group: 'Oceania', text: 'Marshall Islands', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '183', group: 'Oceania', text: 'Micronesia', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '184', group: 'Oceania', text: 'Nauru', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '185', group: 'Oceania', text: 'New Zealand', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '186', group: 'Oceania', text: 'Palau', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '187', group: 'Oceania', text: 'Papua New Guinea', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '188', group: 'Oceania', text: 'Samoa', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '189', group: 'Oceania', text: 'Solomon Islands', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '190', group: 'Oceania', text: 'Tonga', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '191', group: 'Oceania', text: 'Tuvalu', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    { value: '192', group: 'Oceania', text: 'Vanuatu', code: 'en', unitH: 'cm', unitW: 'kg', date: 'MM/dd/yyyy - hh:mm:ss'},
    ];