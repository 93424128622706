import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { BrowserModule } from '@angular/platform-browser';
import { IconsProviderModule } from 'src/app/icons-provider.module';
import { NgZorroAntdModule, NZ_I18N, en_GB } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CategoriesComponent } from '../categories/categories.component';
import { ProductsComponent } from '../products/products.component';
import { AuthService } from 'src/app/services/auth.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { UsersComponent } from '../users/users.component';
import { AdvComponent } from '../adv/adv.component';
import { FiltersComponent } from '../filters/filters.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { FaqComponent } from '../faq/faq.component';
import { QuillModule } from 'ngx-quill';
import { PacksComponent } from '../packs/packs.component';
import { LivingsNotificationsComponent } from '../livings-notifications/livings-notifications.component';

@NgModule({
  declarations: [AdminComponent, 
    CategoriesComponent, 
    ProductsComponent, 
    UsersComponent, 
    AdvComponent, 
    FiltersComponent, 
    NotificationsComponent, 
    FaqComponent, 
    PacksComponent, 
    LivingsNotificationsComponent
  ],
  imports: [CommonModule,
    AdminRoutingModule,
    BrowserModule,
    IconsProviderModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike']
        ],
      },
      theme: 'snow'
    }),
  ],
  exports: [AdminComponent],
  providers: [AuthService, AuthGuard, {provide: NZ_I18N, useValue: en_GB }],
})
export class AdminModule { }
