import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService, UploadFile, UploadXHRArgs } from 'ng-zorro-antd';
import { Observable, Observer } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';

@Component({
  selector: 'app-packs',
  templateUrl: './packs.component.html',
  styleUrls: ['./packs.component.scss']
})
export class PacksComponent implements OnInit {

  dataResult: Living[];
  editCache: { [key: string]: { visibleImage: boolean; visibleIcon: boolean; loading: boolean; edit: boolean; data: Living; } } = {};
  uploadURL = this.globalService.uploadURL + 'changePackImages.php';
  imgPath = this.globalService.imgPath + 'livings/';
  loading = true;
  showForm = false;
  packGroup: FormGroup;

  constructor(private globalService: GlobalService, private dataService: DataService, private message: NzMessageService,
    private http: HttpClient, private fb: FormBuilder) { }

  ngOnInit() {
    this.loading = true;
    this.dataService.getTable('living').subscribe(
      result => {
        this.dataResult = result as Living[];
        this.updateEditCache();
        this.loading = false;
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        this.loading = false;
        console.log('GET TABLE: ', error);
      }
    );

    this.packGroup = this.fb.group({
      code: new FormControl('', [Validators.required]),
      qr: new FormControl('', [Validators.required]),
      title: this.fb.group({
        de: new FormControl('', [Validators.required]),
        el: new FormControl('', [Validators.required]),
        en: new FormControl('', [Validators.required]),
        es: new FormControl('', [Validators.required]),
        fr: new FormControl('', [Validators.required]),
        hu: new FormControl('', [Validators.required]),
        it: new FormControl('', [Validators.required]),
        pl: new FormControl('', [Validators.required]),
        pt: new FormControl('', [Validators.required]),
        ro: new FormControl('', [Validators.required]),
        zh: new FormControl('', [Validators.required]),
        zs: new FormControl('', [Validators.required]),
        ar: new FormControl('', [Validators.required]),
        cs: new FormControl('', [Validators.required]),
        sk: new FormControl('', [Validators.required]),
        vn: new FormControl('', [Validators.required]),
      })
    });
  }


  /*
  * @desc build editCache as a (not deep) copy of dataResult array used for edit functions
  * @param -
  * @return void
  */
  updateEditCache(): void {
    this.dataResult.forEach(item => {
      this.editCache[item.code] = {
        visibleImage: false,
        visibleIcon: false,
        loading: false,
        edit: false,
        data: {
          code: item.code.valueOf(),
          qr: item.qr.valueOf(),
          icon: item.icon.valueOf(),
          image: item.image.valueOf(),
          status: item.status.valueOf(),
          title: this.updateTitleCache(item.title)
        }
      };
    });
  }

  /*
  * @desc build editCache.title as a (not deep) copy of dataResult.title array
  * @param itemTitle -> dataResult[i].title array
  * @return void
  */
  updateTitleCache(itemTitle: Title[]) {
    const title: Title[] = [];
    itemTitle.forEach(titleItem => {
      title.push({
        key: titleItem.key.valueOf(),
        value: titleItem.value.valueOf()
      });
    });
    return title;
  }

  /*
  * @desc start edit action
  * @param code -> dataResult item's code
  * @return void
  */
  startEdit(code: string): void {
    this.editCache[code].edit = true;
  }

  /*
  * @desc cancel edit action. Reset editCache
  * @param -
  * @return void
  */
  cancelEdit(): void {
    this.updateEditCache();
  }

  /*
  * @desc save edit action. call dataService, find and replace edited record in the database
  * @param code -> dataResult item's code
  * @return void
  */
  async saveEdit(code: string) {
    const index = this.dataResult.findIndex(item => item.code === code);

    Object.assign(this.dataResult[index], this.editCache[code].data);
    this.editCache[code].edit = false;
    // build a title string
    let title = '{';
    for (let i = 0; i < this.dataResult[index].title.length; i++) {
      title = title + '"' + this.dataResult[index].title[i].key + '":"' +
        this.dataResult[index].title[i].value.toString() + '"';
      if (i < this.dataResult[index].title.length - 1) {
        title = title + ',';
      }
    }
    title = title + '}';
    const checkSumKey = await this.globalService.encryptDataGlobal(code + title);
    const formData = new FormData();
    formData.append('table', 'livings');
    formData.append('code', code);
    formData.append('qr', this.dataResult[index].qr);
    formData.append('title', title);
    formData.append('checkKey', checkSumKey);
    // call data service, find and REPLACE records
    this.dataService.editTable(formData).subscribe(
      result => {
        this.message.create('success', 'Record edited successfully', { nzDuration: 5000 });
        this.updateEditCache();
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('EDIT: ', error);
      }
    );
  }

  /*
    * @desc delete action. Find and delete record in the database
    * @param code -> dataResult item's code
    * @return void
    */
  async deleteRow(code: string) {
    this.dataResult = this.dataResult.filter(d => d.code !== code);
    const checkSumKey = await this.globalService.encryptDataGlobal(code + 'TblLivings');
    const formData = new FormData();
    formData.append('table', 'TblLivings');
    formData.append('code', code);
    formData.append('checkKey', checkSumKey);
    // call data service, DELETE record
    this.dataService.deleteRowTable(formData).subscribe(
      result => {
        this.message.create('success', 'Record deleted successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'An error occured', { nzDuration: 5000 });
        console.log('DELETE: ', error);
      }
    );
  }

  /*
  * @desc change state action. Find and change record's status in the database
  * @param code -> dataResult item's code
  * @return void
  */
  async onClickSwitch(code: string) {
    const index = this.dataResult.findIndex(item => item.code === code);
    this.dataResult[index].status = !this.dataResult[index].status;
    this.editCache[code].data.status = !this.editCache[code].data.status;

    const checkSumKey = await this.globalService.encryptDataGlobal(code + 'TblLivings');
    const formData = new FormData();
    formData.append('table', 'TblLivings');
    formData.append('code', code);
    formData.append('checkKey', checkSumKey);
    this.dataService.editStatus(formData).subscribe(
      result => {
        this.message.create('success', 'Status changed successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('CHANGE SWITCH: ', error);
      }
    );
  }

  /*
  * @desc add action. add record in the database
  * @param -
  * @return void
  */
  async addRow() {
    // IF FORM INPUT CODE ALREADY EXISTS -> SHOW ERROR
    const codeIndex = this.dataResult.findIndex(item => item.code === this.packGroup.value.code);
    if (codeIndex > -1) {
      this.message.create('error', 'Pack\'s code already exists', { nzDuration: 5000 });
    } else if (this.packGroup.invalid) {
      this.message.create('error', 'All * fields are required', { nzDuration: 5000 });
    } else {
      const title = (JSON.stringify(this.packGroup.value.title));
      const checkSumKey = await this.globalService.encryptDataGlobal(
        this.packGroup.value.code + this.packGroup.value.qr + title);
      const formData = new FormData();
      formData.append('table', 'livings');
      formData.append('code', this.packGroup.value.code);
      formData.append('qr', this.packGroup.value.qr);
      formData.append('title', title);
      formData.append('checkKey', checkSumKey);

      this.dataService.addRowToTable(formData).subscribe(
        result => {
          this.message.create('success', 'Row added successfully', { nzDuration: 5000 });
          // refresh editCache and dataResult with a new row
          this.ngOnInit();
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          console.log('ADD: ', error);
        }
      );
    }
  }

  /*
  * @desc change icon action. save icon as "dataResult.code_thb" in this.imgPath
  * @param item -> uploaded icon name
  * @return void
  */
  customIconReq = (item: UploadXHRArgs) => {
    // Create a FormData here to store files and other parameters.
    const formData = new FormData();
    formData.append('file', item.file as any);
    formData.append('field', '\_thb');
    formData.append('filename', item.name);
    formData.append('ext', item.file.name.substr(item.file.name.lastIndexOf('.')));
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });
    // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      err => {
        item.onError!(err, item.file!);
      }
    );
  }

  /*
  * @desc change image action. save icon as "dataResult.code" in this.imgPath
  * @param item -> uploaded image name
  * @return void
  */
  customReq = (item: UploadXHRArgs) => {
    // Create a FormData here to store files and other parameters.
    const formData = new FormData();
    formData.append('file', item.file as any);
    formData.append('field', '');
    formData.append('filename', item.name);
    formData.append('ext', item.file.name.substr(item.file.name.lastIndexOf('.')));
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });
    // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      err => {
        item.onError!(err, item.file!);
      }
    );
  }

  /*
  * @desc edit/add image and icon name action. find and add/edit image and icon in database
  * @param info -> uploaded file;
  * @param code -> dataResult.code;
  * @param field -> "image" || "icon";
  * @return void
  */
  async handleChange(info: { file: UploadFile }, code: string, field: string) {
    switch (info.file.status) {
      case 'uploading':
        this.editCache[code].loading = true;
        break;
      case 'done':
        const data: Living = this.editCache[code].data;
        const fileExtension = info.file.name.substr(info.file.name.lastIndexOf('.'));
        const index = this.dataResult.findIndex(item => item.code === code);
        // ****************************************ICON UPDATING
        if (field === 'icon') {
          // CUSTOMIZED ICON NAME
          data.icon = data.code + '\_thb' + fileExtension;
          const checkSumKey = await this.globalService.encryptDataGlobal(data.code + field + data.icon);
          // ENTER HERE WHEN:
          // 1- data.icon doesn't exists
          // 2- upload a new icon with different extension
          if (!this.dataResult[index].icon.includes(data.icon)) {
            const formData = new FormData();
            formData.append('table', 'TblLivings');
            formData.append('code', data.code);
            formData.append('file', data.icon as any);
            formData.append('field', 'icon');
            formData.append('checkKey', checkSumKey);

            this.dataService.uploadFiles(formData).subscribe(
              result => {
                this.message.create('success', 'Icon added successfully', { nzDuration: 5000 });
              },
              error => {
                this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
                console.log('UPLOAD FILE: ', error);
              }
            );
          } else {
            this.message.create('success', 'Icon changed successfully', { nzDuration: 5000 });
            // REFRESH URL
            data.icon += '?random+\=' + Math.random();
            this.editCache[code].loading = true;
          }
        } else if (field === 'image') {
          // CUSTOMIZED IMAGE NAME
          data.image = data.code + fileExtension;
          const checkSumKey = await this.globalService.encryptDataGlobal(data.code + field + data.image);
          // ENTER HERE WHEN:
          // 1- data.image doesn't exists
          // 2- upload a new image with different extension
          if (!this.dataResult[index].image.includes(data.image)) {
            const formData = new FormData();
            formData.append('table', 'TblLivings');
            formData.append('code', data.code);
            formData.append('file', data.image as any);
            formData.append('field', 'image');
            formData.append('checkKey', checkSumKey);

            this.dataService.uploadFiles(formData).subscribe(
              result => {
                this.message.create('success', 'Image added successfully', { nzDuration: 5000 });
                this.updateEditCache();
              },
              error => {
                this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
                console.log('UPLOAD FILE: ', error);
              }
            );
          } else {
            this.message.create('success', 'Image changed successfully', { nzDuration: 5000 });
            // REFRESH URL
            data.image += '?random+\=' + Math.random();
            this.editCache[code].loading = true;
          }
        }
        // ASSIGN EDITED PICTURE
        Object.assign(this.dataResult[index], this.editCache[code].data);

        this.editCache[code].loading = false;
        break;
      case 'error':
        this.message.create('error', 'An error occured', { nzDuration: 5000 });
        console.log('HANDLE CHANGE: ', info.file.status, info.file, code);
        break;
    }
  }

  /*
  * @desc check if uploaded image is valid before insert into database and save
  * @param file -> uploaded image
  * @return boolean, true if valid, false otherwise
  */
  beforeUpload = (file: UploadFile) => {
    return new Observable((observer: Observer<boolean>) => {
      // CHECK EXTENSION TYPES
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isJPG && !isPNG) {
        this.message.create('error', 'Only .png and .jpg files are supported', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      // CHECK SIZE file max size -> 2MB
      const isLt2M = file.size < 2097152;
      if (!isLt2M) {
        this.message.create('error', 'File size must be under 2MB', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      observer.next((isJPG || isPNG) && isLt2M);
      observer.complete();
    });
  }

  /*
  * @desc check if uploaded icon is valid before insert into database and save
  * @param file -> uploaded icon
  * @return boolean, true if valid, false otherwise
  */
  beforeUploadIcon = (file: UploadFile) => {
    return new Observable((observer: Observer<boolean>) => {
      // CHECK EXTENSION TYPES
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isJPG && !isPNG) {
        this.message.create('error', 'Only .png and .jpg files are supported', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      // CHECK SIZE file max size -> 2MB
      const isLt2M = file.size < 2097152;
      if (!isLt2M) {
        this.message.create('error', 'File size must be under 2MB', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      // CHECK DIMENSION
      this.checkIconDimension(file, 512, 512).then(dimensionRes => {
        if (!dimensionRes) {
          this.message.create('error', 'File dimension must be 512x512', { nzDuration: 5000 });
          observer.complete();
          return;
        }
        observer.next((isJPG || isPNG) && isLt2M && dimensionRes);
        observer.complete();
      });
    });
  }
  checkIconDimension(file: UploadFile, maxW: number, maxH: number): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src!);
        resolve(width === maxW && height === maxH);
      };
    });
  }
}
