import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { CategoriesComponent } from '../categories/categories.component';
import { ProductsComponent } from '../products/products.component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { UsersComponent } from '../users/users.component';
import { AdvComponent } from '../adv/adv.component';
import { FiltersComponent } from '../filters/filters.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { FaqComponent } from '../faq/faq.component';
import { FrontComponent } from '../front/front.component';
import { PacksComponent } from '../packs/packs.component';
import { LivingsNotificationsComponent } from '../livings-notifications/livings-notifications.component';


const routes: Routes = [
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    children: [
      {path: '', pathMatch: 'full', redirectTo:'categories'},
      {path: 'categories', component: CategoriesComponent },
      {path: 'products', component: ProductsComponent},
      {path: 'users', component: UsersComponent},
      {path: 'adv', component: AdvComponent},
      {path: 'filters', component: FiltersComponent},
      {path: 'filters-notifications', component: NotificationsComponent},
      {path: 'faq', component: FaqComponent},
      {path: 'livings', component: PacksComponent},
      {path: 'livings-notifications', component: LivingsNotificationsComponent},
    ]
  },  
  { path: 'front/faq', component: FrontComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }