import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NzMessageService, UploadXHRArgs, UploadFile } from 'ng-zorro-antd';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/shared/global.service';
import { HttpRequest, HttpEvent, HttpEventType, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'app-adv',
  templateUrl: './adv.component.html',
  styleUrls: ['./adv.component.scss']
})
export class AdvComponent implements OnInit {
  dataResult: Adv[];
  displayedData: Adv[];
  editCache: { [key: string]: { visibleImage: boolean; loading: boolean; edit: boolean; data: Adv; } } = {};
  listOfLanguages: any[] = this.globalService.advLanguagesCode;
  listOfStatus: any[] = [{ 'value': true, 'text': 'Active' }, { 'value': false, 'text': 'No active' }];
  listOfSearchLanguages: string[] = [];
  searchStatus: boolean;
  imgPath = this.globalService.imgPath + 'adv/';
  uploadURL = this.globalService.uploadURL + 'changeAdvImages.php';
  advGroup: FormGroup;
  loading = true;
  showForm: boolean = false;

  constructor(private globalService: GlobalService, private dataService: DataService,
              private http: HttpClient, private message: NzMessageService, private fb: FormBuilder) { }

  ngOnInit() {
    this.dataService.getTable('adv').subscribe(
      result => {
        this.dataResult = <Adv[]> result;
        this.displayedData = this.dataResult;
        this.updateEditCache();
        this.loading = false;
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('GET TABLE: ', error);
        this.loading = false;
      }
    );
    this.advGroup = this.fb.group({
      title: new FormControl('', [Validators.required]),
      languageCode: new FormControl('', []),
      dateStart: new FormControl(null, [Validators.required]),
      dateEnd: new FormControl(null, [Validators.required]),
      link: new FormControl('', []),
    });
  }

  /*
  * @desc build editCache as a (not deep) copy of dataResult array used for edit functions
  * @param -
  * @return void
  */
  updateEditCache(): void {
    this.dataResult.forEach(item => {
      this.editCache[item.code] = {
        visibleImage: false,
        loading: false,
        edit: false,
        data: { ...item }
      };
    });
    this.displayedData = this.dataResult;
  }

  /*
  * @desc filt dataResult in function of item's languages or status
  * @param listOfLanguages -> array of inputed languages
  * @param status -> 1||0
  * @return void
  */
  filter(listOfLanguages: string[], status: boolean): void {
    this.listOfSearchLanguages = listOfLanguages;
    this.searchStatus = status;
    this.displayedData = this.dataResult.filter(item => this.filterFunc(item));
  }
  filterFunc = (item: Adv) => {
    const hasStatus: boolean = ((typeof this.searchStatus !== 'undefined') && (this.searchStatus !== null)
      // tslint:disable-next-line: triple-equals
      ? (item.status == this.searchStatus) : true);
    const hasLanguage: boolean = (this.listOfSearchLanguages.length
      ? this.listOfSearchLanguages.some(language => item.languageCode.indexOf(language) !== -1) : true);
    return hasStatus && hasLanguage;
  }

  /*
  * @desc reset selected filters
  * @param -
  * @return void
  */
  resetFilters(): void {
    this.listOfStatus = [{ 'value': true, 'text': 'Active' }, { 'value': false, 'text': 'No active' }];
    this.listOfLanguages = this.globalService.advLanguagesCode;
    this.listOfSearchLanguages = [];
    this.searchStatus = null;
    this.filter(this.listOfSearchLanguages, this.searchStatus);
  }

  /*
  * @desc start edit action
  * @param code -> dataResult item's code
  * @return void
  */
  startEdit(code: string): void {
    this.editCache[code].edit = true;
  }

  /*
  * @desc cancel edit action. Reset editCache
  * @param -
  * @return void
  */
  cancelEdit(code: string): void {
    const index = this.dataResult.findIndex(item => item.code === code);
    this.editCache[code] = {
      visibleImage: false,
      data: { ...this.dataResult[index] },
      loading: false,
      edit: false
    };
  }

  /*
  * @desc save edit action. call dataService, find and replace edited record in the database
  * @param code -> dataResult item's code
  * @return void
  */
  async saveEdit(code: string) {
    const index = this.dataResult.findIndex(item => item.code === code);
    Object.assign(this.dataResult[index], this.editCache[code].data);
    this.editCache[code].edit = false;

    const checkSumKey = await this.globalService.encryptDataGlobal(
      code + this.dataResult[index].title + this.dataResult[index].languageCode + this.dataResult[index].link);
    const formData = new FormData();
    formData.append('table', 'adv');
    formData.append('code', code);
    formData.append('title', this.dataResult[index].title);
    formData.append('language', this.dataResult[index].languageCode);
    formData.append('link', this.dataResult[index].link);
    formData.append('dateStart', ((this.dataResult[index].dateStart) / 1000).toString());
    formData.append('dateEnd',  ((this.dataResult[index].dateEnd) / 1000).toString());
    formData.append('checkKey', checkSumKey);
    // call data service, find and REPLACE records
    this.dataService.editTable(formData).subscribe(
      result => {
        this.message.create('success', 'Record edited successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('EDIT: ', error);
      }
    );
  }

  /*
  * @desc delete action. Find and delete record in the database
  * @param code -> dataResult item's code
  * @return void
  */
  async deleteRow(code: string) {
    this.dataResult = this.dataResult.filter(d => d.code !== code);
    const checkSumKey = await this.globalService.encryptDataGlobal(code + 'TblAdv');

    const formData = new FormData();
    formData.append('table', 'TblAdv');
    formData.append('code', code);
    formData.append('checkKey', checkSumKey);
    // call data service, DELETE record
    this.dataService.deleteRowTable(formData).subscribe(
      result => {
        this.message.create('success', 'Record deleted successfully', { nzDuration: 5000 });
        this.updateEditCache();
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('DELETE: ', error);
      }
    );
  }

  /*
  * @desc add action. add record in the database
  * @param -
  * @return void
  */
  async addRow() {
    if (this.advGroup.invalid) {
      this.message.create('error', 'All * fields are required', { nzDuration: 5000 });
    } else {
      const dateStart: string = (Math.trunc((this.advGroup.value.dateStart.getTime()) / 1000)).toString();
      const dateEnd: string = (Math.trunc((this.advGroup.value.dateEnd.getTime()) / 1000)).toString();

      const checkSumKey = await this.globalService.encryptDataGlobal(
        this.advGroup.value.title + this.advGroup.value.languageCode + this.advGroup.value.link);
      const formData = new FormData();
      formData.append('table', 'adv');
      formData.append('title', this.advGroup.value.title);
      formData.append('languageCode', this.advGroup.value.languageCode);
      formData.append('dateStart', dateStart);
      formData.append('dateEnd', dateEnd);
      formData.append('link', this.advGroup.value.link);
      formData.append('checkKey', checkSumKey);
      this.dataService.addRowToTable(formData).subscribe(
        result => {
          this.message.create('success', 'Row added successfully', { nzDuration: 5000 });
          // refresh editCache and dataResult with a new row
          this.ngOnInit();
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          console.log('ADD: ', error);
        }
      );
    }
  }

  /*
  * @desc change state action. Find and change record's status in the database
  * @param code -> dataResult item's code
  * @return void
  */
  async onClickSwitch(code: string) {
    const index = this.dataResult.findIndex(item => item.code === code);
    this.dataResult[index].status = !this.dataResult[index].status;
    this.editCache[code].data.status = !this.editCache[code].data.status;

    const checkSumKey = await this.globalService.encryptDataGlobal(code + 'TblAdv');
    const formData = new FormData();
    formData.append('table', 'TblAdv');
    formData.append('code', code);
    formData.append('checkKey', checkSumKey);
    this.dataService.editStatus(formData).subscribe(
      result => {
        this.message.create('success', 'Status changed successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('CHANGE SWITCH: ', error);
      }
    );
  }

  /*
  * @desc change icon or image action. save icon/image as "dataResult.code-icon/image" in this.imgPath
  * @param item -> uploaded icon name
  * @return void
  */
  customReq = (item: UploadXHRArgs) => {
    // Create a FormData here to store files and other parameters.
    const formData = new FormData();
    formData.append('file', item.file as any);
    formData.append('field', 'adv');
    formData.append('filename', item.name);
    formData.append('ext', item.file.name.substr(item.file.name.lastIndexOf('.')));
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });
    // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      err => {
        item.onError!(err, item.file!);
      }
    );
  }

  /*
  * @desc edit/add image and icon name action. find and add/edit image and icon in database
  * @param info -> uploaded file;
  * @param code -> dataResult.code;
  * @param field -> "image" || "icon";
  * @return void
  */
  async handleChange(info: { file: UploadFile }, code: string) {
    switch (info.file.status) {
      case 'uploading':
        this.editCache[code].loading = true;
        break;
      case 'done':
        const data: Adv = this.editCache[code].data;
        const fileExtension = info.file.name.substr(info.file.name.lastIndexOf('.'));
        const index = this.dataResult.findIndex(item => item.code === code);
        // ****************************************IMAGE UPDATING
        // CUSTOMIZED IMAGE NAME
        data.image = 'adv' + data.code + fileExtension;
        const checkSumKey = await this.globalService.encryptDataGlobal(data.code + 'image' + data.image);
        // ENTER HERE WHEN:
        // 1- data.image doesn't exists
        // 2- upload a new image with different extension
        if (!this.dataResult[index].image.includes(data.image)) {
          const formData = new FormData();
          formData.append('table', 'TblAdv');
          formData.append('code', data.code);
          formData.append('file', data.image as any);
          formData.append('field', 'image');
          formData.append('checkKey', checkSumKey);
          this.dataService.uploadFiles(formData).subscribe(
            result => {
              this.message.create('success', 'Image added successfully', { nzDuration: 5000 });
              this.updateEditCache();
            },
            error => {
              this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
              console.log('UPLOAD FILE: ', error);
            }
          );
        } else {
          this.message.create('success', 'Image changed successfully', { nzDuration: 5000 });
          // REFRESH URL
          data.image += '?random+\=' + Math.random();
          this.editCache[code].loading = true;
        }
        // ASSIGN EDITED PICTURE
        Object.assign(this.dataResult[index], this.editCache[code].data);
        this.editCache[code].loading = false;
        break;
      case 'error':
        this.message.create('error', 'An error occured', { nzDuration: 5000 });
        console.log('HANDLE CHANGE: ', info.file.status, info.file, code);
        break;
    }
  }

  /*
  * @desc check if uploaded image/icon is valid before insert into database and save
  * @param file -> uploaded image/icon
  * @return boolean, true if valid, false otherwise
  */
  beforeUpload = (file: UploadFile) => {
    return new Observable((observer: Observer<boolean>) => {
      // CHECK EXTENSION TYPES
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isJPG && !isPNG) {
        this.message.create('error', 'Only .png and .jpg files are supported', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      // CHECK SIZE file max size -> 2MB
      const isLt2M = file.size < 2097152;
      if (!isLt2M) {
        this.message.create('error', 'File size must be under 2MB', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      observer.next((isJPG || isPNG) && isLt2M);
      observer.complete();
    });
  }
}
