import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminRoutingModule } from './pages/admin/admin-routing.module';
import { FrontComponent } from './pages/front/front.component';
import { LoginComponent } from './pages/login/login.component';

const routes: Routes = [
  { path: '**', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes), AdminRoutingModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
