import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NzMessageService, UploadXHRArgs, UploadFile } from 'ng-zorro-antd';
import { GlobalService } from 'src/app/shared/global.service';
import { HttpRequest, HttpEvent, HttpEventType, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { globalNations } from '../../interfaces/countries';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  dataResult: Filter[];
  editCache: { [key: string]: { visibleImage: boolean; visibleIcon: boolean; loading: boolean; edit: boolean; data: Filter; } } = {};
  uploadURL = this.globalService.uploadURL + 'changeFilterImages.php';
  imgPath = this.globalService.imgPath + 'filters/';
  loading = true;
  filterGroup: FormGroup;
  listOfCountries: any[] = this.globalService.filterCountryCode;
  showForm = false;
  listOfAllNations = globalNations;

  constructor(private globalService: GlobalService, private dataService: DataService, private message: NzMessageService,
    private http: HttpClient, private fb: FormBuilder) { }

  ngOnInit() {
    this.loading = true;
    this.dataService.getTable('filters').subscribe(
      result => {
        this.dataResult = result as Filter[];
        this.dataResult.forEach(data => {
          const countryArray = data.countries;
          data.countries = [];
          countryArray.forEach(nat => {
            data.countries.push(this.listOfAllNations[this.listOfAllNations.findIndex(search => search.value === nat)]);
          });
        });
        this.updateEditCache();
        this.loading = false;
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        this.loading = false;
        console.log('GET TABLE: ', error);
      }
    );
    this.filterGroup = this.fb.group({
      code: new FormControl('', [Validators.required]),
      qr: new FormControl('', [Validators.required]),
      title: this.fb.group({
        de: new FormControl('', [Validators.required]),
        el: new FormControl('', [Validators.required]),
        en: new FormControl('', [Validators.required]),
        es: new FormControl('', [Validators.required]),
        fr: new FormControl('', [Validators.required]),
        hu: new FormControl('', [Validators.required]),
        it: new FormControl('', [Validators.required]),
        pl: new FormControl('', [Validators.required]),
        pt: new FormControl('', [Validators.required]),
        ro: new FormControl('', [Validators.required]),
        zh: new FormControl('', [Validators.required]),
        zs: new FormControl('', [Validators.required]),
        ar: new FormControl('', [Validators.required]),
        cs: new FormControl('', [Validators.required]),
        sk: new FormControl('', [Validators.required]),
        vn: new FormControl('', [Validators.required]),
      }),
      param_1: this.fb.array([
        this.fb.group({
          key: new FormControl(''),
          active: new FormControl(false),
          multiplier: new FormControl(''),
          duration: new FormControl(''),
        })
      ]),
      param_2: this.fb.array([
        this.fb.group({
          key: new FormControl(''),
          active: new FormControl(false),
          multiplier: new FormControl(''),
          duration: new FormControl(''),
        })
      ]),
      param_3: this.fb.array([
        this.fb.group({
          key: new FormControl(''),
          active: new FormControl(false),
          multiplier: new FormControl(''),
          duration: new FormControl(''),
        })
      ]),
      param_4: this.fb.array([
        this.fb.group({
          key: new FormControl(''),
          active: new FormControl(false),
          multiplier: new FormControl(''),
          duration: new FormControl(''),
        })
      ]),
    });
  }

  get param1() {
    return this.filterGroup.get('param_1') as FormArray;
  }
  get param2() {
    return this.filterGroup.get('param_2') as FormArray;
  }
  get param3() {
    return this.filterGroup.get('param_3') as FormArray;
  }
  get param4() {
    return this.filterGroup.get('param_4') as FormArray;
  }

  /*
  * @desc dinamically push a row on add form's param array
  * @param par -> "param1"||"param2"||"param3"||"param4"
  * @return void
  */
  addParamToForm(par: string) {
    const param = this.filterGroup.get(par) as FormArray;
    param.push(this.fb.group({
      active: new FormControl(false, [Validators.required]),
      key: new FormControl(''),
      multiplier: new FormControl('', [Validators.required]),
      duration: new FormControl('', [Validators.required]),
    })
    );
  }

  /*
  * @desc dinamically remove a row on add form's param array
  * @param par -> "param1"||"param2"||"param3"||"param4"
  * @param index -> par's row index
  * @return void
  */
  deleteParamToForm(index: number, par: string) {
    const param = this.filterGroup.get(par) as FormArray;
    param.removeAt(index);
  }

  /*
  * @desc build editCache as a (not deep) copy of dataResult array used for edit functions
  * @param -
  * @return void
  */
  updateEditCache(): void {
    this.dataResult.forEach(item => {
      this.editCache[item.code] = {
        visibleImage: false,
        visibleIcon: false,
        loading: false,
        edit: false,
        data: {
          code: item.code.valueOf(),
          qr: item.qr.valueOf(),
          title: this.updateTitleCache(item.title),
          image: item.image.valueOf(),
          icon: item.icon.valueOf(),
          par1: this.updateParamCache(item.par1),
          par2: this.updateParamCache(item.par2),
          par3: this.updateParamCache(item.par3),
          par4: this.updateParamCache(item.par4),
          status: item.status.valueOf(),
          countries: this.updateCountriesCache(item.countries)
        }
      };
    });
  }

  updateCountriesCache(countries: any[]) {
    const countryText: any[] = [];
    countries.forEach(item => {
      countryText.push(item.text);
    });
    return countryText;
  }

  /*
  * @desc build editCache.title as a (not deep) copy of dataResult.title array
  * @param itemTitle -> dataResult[i].title array
  * @return void
  */
  updateTitleCache(itemTitle: Title[]) {
    const title: Title[] = [];
    itemTitle.forEach(titleItem => {
      title.push({
        key: titleItem.key.valueOf(),
        value: titleItem.value.valueOf()
      });
    });
    return title;
  }

  /*
  * @desc build editCache.param as a (not deep) copy of dataResult.param array
  * @param itemParam -> dataResult[i].param array
  * @return void
  */
  updateParamCache(itemParam: Param[]) {
    const par: Param[] = [];
    itemParam.forEach(paramItem => {
      par.push({
        key: paramItem.key.valueOf(),
        active: paramItem.active.valueOf(),
        multiplier: paramItem.multiplier.valueOf(),
        duration: paramItem.duration.valueOf()
      });
    });
    return par;
  }

  /*
  * @desc start edit action
  * @param code -> dataResult item's code
  * @return void
  */
  startEdit(code: string): void {
    this.editCache[code].edit = true;
  }

  /*
  * @desc cancel edit action. Reset editCache
  * @param -
  * @return void
  */
  cancelEdit(): void {
    this.updateEditCache();
  }

  /*
  * @desc save edit action. call dataService, find and replace edited record in the database
  * @param code -> dataResult item's code
  * @return void
  */
  async saveEdit(code: string) {
    if (!this.validateEditParam(this.editCache[code].data.par1) ||
      !this.validateEditParam(this.editCache[code].data.par2) ||
      !this.validateEditParam(this.editCache[code].data.par3) ||
      !this.validateEditParam(this.editCache[code].data.par4)) {
      return;
    }
    const index = this.dataResult.findIndex(item => item.code === code);
    const countryArray = this.editCache[code].data.countries;
    this.editCache[code].data.countries = [];
    countryArray.forEach(nat => {
      const i = this.listOfAllNations.findIndex(search => search.text === nat);
      if (i !== -1) {
        this.editCache[code].data.countries.push(this.listOfAllNations[i]);
      }
    });
    Object.assign(this.dataResult[index], this.editCache[code].data);
    this.editCache[code].edit = false;
    // build a title string
    let title = '{';
    for (let i = 0; i < this.dataResult[index].title.length; i++) {
      title = title + '"' + this.dataResult[index].title[i].key + '":"' +
        this.dataResult[index].title[i].value.toString() + '"';
      if (i < this.dataResult[index].title.length - 1) {
        title = title + ',';
      }
    }
    title = title + '}';
    const par1: string = this.buildParamString(this.dataResult[index].par1);
    const par2: string = this.buildParamString(this.dataResult[index].par2);
    const par3: string = this.buildParamString(this.dataResult[index].par3);
    const par4: string = this.buildParamString(this.dataResult[index].par4);
    let countriesIDs = '[';
    this.dataResult[index].countries.forEach((data, index, array) => {
      countriesIDs = countriesIDs + '"' + data.value + '"';
      if (index !== array.length - 1) {
        countriesIDs = countriesIDs + ',';
      }
    });
    countriesIDs = countriesIDs + ']';
    const checkSumKey = await this.globalService.encryptDataGlobal(code + title + par1 + par2 + par3 + par4);
    const formData = new FormData();
    formData.append('table', 'filters');
    formData.append('code', code);
    formData.append('qr', this.dataResult[index].qr);
    formData.append('title', title);
    formData.append('par1', par1);
    formData.append('par2', par2);
    formData.append('par3', par3);
    formData.append('par4', par4);
    formData.append('countries', countriesIDs);
    formData.append('checkKey', checkSumKey);
    // call data service, find and REPLACE records
    this.dataService.editTable(formData).subscribe(
      result => {
        this.message.create('success', 'Record edited successfully', { nzDuration: 5000 });
        this.updateEditCache();
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('EDIT: ', error);
      }
    );
  }
  
  /*
  * @desc check if edited parameters are valid
  * @param data -> checked param
  * @return boolean: true if param is valid, false otherwise
  */
  validateEditParam(data: Param[]) {
    for (let i = 0; i < data.length; i++) {
      if (!data[i].multiplier || !data[i].duration) {
        this.message.create('error', 'Parameter must be not empty', { nzDuration: 5000 });
        return false;
      }
    }
    return true;
  }

  /*
  * @desc build a string from param json
  * @param par -> dataResult.param
  * @return string of param json
  */
  buildParamString(par: Param[]): string {
    let result = '{';
    for (let i = 0; i < par.length; i++) {
      const active = par[i].active ? '1' : '0';
      result = result + '"' + par[i].key + '":{"active":"' +
        active + '","multiplier":"' + par[i].multiplier + '","duration":"' +
        par[i].duration + '"}';
      if (i < par.length - 1) {
        result = result + ',';
      }
    }
    result = result + '}';
    return result;
  }

  /*
  * @desc delete action. Find and delete record in the database
  * @param code -> dataResult item's code
  * @return void
  */
  async deleteRow(code: string) {
    this.dataResult = this.dataResult.filter(d => d.code !== code);

    const checkSumKey = await this.globalService.encryptDataGlobal(code + 'TblFilters');
    const formData = new FormData();
    formData.append('table', 'TblFilters');
    formData.append('code', code);
    formData.append('checkKey', checkSumKey);
    // call data service, DELETE record
    this.dataService.deleteRowTable(formData).subscribe(
      result => {
        this.message.create('success', 'Record deleted successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'An error occured', { nzDuration: 5000 });
        console.log('DELETE: ', error);
      }
    );
  }

  /*
  * @desc change state action. Find and change record's status in the database
  * @param code -> dataResult item's code
  * @return void
  */
  async onClickSwitch(code: string) {
    const index = this.dataResult.findIndex(item => item.code === code);
    this.dataResult[index].status = !this.dataResult[index].status;
    this.editCache[code].data.status = !this.editCache[code].data.status;

    const checkSumKey = await this.globalService.encryptDataGlobal(code + 'TblFilters');
    const formData = new FormData();
    formData.append('table', 'TblFilters');
    formData.append('code', code);
    formData.append('checkKey', checkSumKey);
    this.dataService.editStatus(formData).subscribe(
      result => {
        this.message.create('success', 'Status changed successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.log('CHANGE SWITCH: ', error);
      }
    );
  }

  /*
  * @desc add action. add record in the database
  * @param -
  * @return void
  */
  async addRow() {
    // IF FORM INPUT CODE ALREADY EXISTS -> SHOW ERROR
    const codeIndex = this.dataResult.findIndex(item => item.code === this.filterGroup.value.code);
    if (codeIndex > -1) {
      this.message.create('error', 'Filter\'s code already exists', { nzDuration: 5000 });
    } else if (this.filterGroup.invalid) {
      this.message.create('error', 'All * fields are required', { nzDuration: 5000 });
    } else {
      const title = (JSON.stringify(this.filterGroup.value.title));
      const par1: string = this.buildParamString(this.filterGroup.value.param_1);
      const par2: string = this.buildParamString(this.filterGroup.value.param_2);
      const par3: string = this.buildParamString(this.filterGroup.value.param_3);
      const par4: string = this.buildParamString(this.filterGroup.value.param_4);

      const checkSumKey = await this.globalService.encryptDataGlobal(
        this.filterGroup.value.code + this.filterGroup.value.qr + title + par1 + par2 + par3 + par4);
      const formData = new FormData();
      formData.append('table', 'filters');
      formData.append('code', this.filterGroup.value.code);
      formData.append('qr', this.filterGroup.value.qr);
      formData.append('title', title);
      formData.append('par1', par1);
      formData.append('par2', par2);
      formData.append('par3', par3);
      formData.append('par4', par4);
      formData.append('checkKey', checkSumKey);

      this.dataService.addRowToTable(formData).subscribe(
        result => {
          this.message.create('success', 'Row added successfully', { nzDuration: 5000 });
          // refresh editCache and dataResult with a new row
          this.ngOnInit();
        },
        error => {
          this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
          console.log('ADD: ', error);
        }
      );
    }
  }

  /*
  * @desc change icon action. save icon as "dataResult.code_thb" in this.imgPath
  * @param item -> uploaded icon name
  * @return void
  */
  customIconReq = (item: UploadXHRArgs) => {
    // Create a FormData here to store files and other parameters.
    const formData = new FormData();
    formData.append('file', item.file as any);
    formData.append('field', '\_thb');
    formData.append('filename', item.name);
    formData.append('ext', item.file.name.substr(item.file.name.lastIndexOf('.')));
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });
    // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      err => {
        item.onError!(err, item.file!);
      }
    );
  }

  /*
  * @desc change image action. save icon as "dataResult.code" in this.imgPath
  * @param item -> uploaded image name
  * @return void
  */
  customReq = (item: UploadXHRArgs) => {
    // Create a FormData here to store files and other parameters.
    const formData = new FormData();
    formData.append('file', item.file as any);
    formData.append('field', '');
    formData.append('filename', item.name);
    formData.append('ext', item.file.name.substr(item.file.name.lastIndexOf('.')));
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });
    // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      err => {
        item.onError!(err, item.file!);
      }
    );
  }
  /*
  * @desc edit/add image and icon name action. find and add/edit image and icon in database
  * @param info -> uploaded file;
  * @param code -> dataResult.code;
  * @param field -> "image" || "icon";
  * @return void
  */
  async handleChange(info: { file: UploadFile }, code: string, field: string) {
    switch (info.file.status) {
      case 'uploading':
        this.editCache[code].loading = true;
        break;
      case 'done':
        const data: Filter = this.editCache[code].data;
        const fileExtension = info.file.name.substr(info.file.name.lastIndexOf('.'));
        const index = this.dataResult.findIndex(item => item.code === code);
        // ****************************************ICON UPDATING
        if (field === 'icon') {
          // CUSTOMIZED ICON NAME
          data.icon = data.code + '\_thb' + fileExtension;
          const checkSumKey = await this.globalService.encryptDataGlobal(data.code + field + data.icon);
          // ENTER HERE WHEN:
          // 1- data.icon doesn't exists
          // 2- upload a new icon with different extension
          if (!this.dataResult[index].icon.includes(data.icon)) {
            const formData = new FormData();
            formData.append('table', 'TblFilters');
            formData.append('code', data.code);
            formData.append('file', data.icon as any);
            formData.append('field', 'icon');
            formData.append('checkKey', checkSumKey);

            this.dataService.uploadFiles(formData).subscribe(
              result => {
                this.message.create('success', 'Icon added successfully', { nzDuration: 5000 });
              },
              error => {
                this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
                console.log('UPLOAD FILE: ', error);
              }
            );
          } else {
            this.message.create('success', 'Icon changed successfully', { nzDuration: 5000 });
            // REFRESH URL
            data.icon += '?random+\=' + Math.random();
            this.editCache[code].loading = true;
          }
        } else if (field === 'image') {
          // CUSTOMIZED IMAGE NAME
          data.image = data.code + fileExtension;
          const checkSumKey = await this.globalService.encryptDataGlobal(data.code + field + data.image);
          // ENTER HERE WHEN:
          // 1- data.image doesn't exists
          // 2- upload a new image with different extension
          if (!this.dataResult[index].image.includes(data.image)) {
            const formData = new FormData();
            formData.append('table', 'TblFilters');
            formData.append('code', data.code);
            formData.append('file', data.image as any);
            formData.append('field', 'image');
            formData.append('checkKey', checkSumKey);

            this.dataService.uploadFiles(formData).subscribe(
              result => {
                this.message.create('success', 'Image added successfully', { nzDuration: 5000 });
                this.updateEditCache();
              },
              error => {
                this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
                console.log('UPLOAD FILE: ', error);
              }
            );
          } else {
            this.message.create('success', 'Image changed successfully', { nzDuration: 5000 });
            // REFRESH URL
            data.image += '?random+\=' + Math.random();
            this.editCache[code].loading = true;
          }
        }
        // ASSIGN EDITED PICTURE
        Object.assign(this.dataResult[index], this.editCache[code].data);

        this.editCache[code].loading = false;
        break;
      case 'error':
        this.message.create('error', 'An error occured', { nzDuration: 5000 });
        console.log('HANDLE CHANGE: ', info.file.status, info.file, code);
        break;
    }
  }

  /*
  * @desc check if uploaded image is valid before insert into database and save
  * @param file -> uploaded image
  * @return boolean, true if valid, false otherwise
  */
  beforeUpload = (file: UploadFile) => {
    return new Observable((observer: Observer<boolean>) => {
      // CHECK EXTENSION TYPES
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isJPG && !isPNG) {
        this.message.create('error', 'Only .png and .jpg files are supported', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      // CHECK SIZE file max size -> 2MB
      const isLt2M = file.size < 2097152;
      if (!isLt2M) {
        this.message.create('error', 'File size must be under 2MB', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      observer.next((isJPG || isPNG) && isLt2M);
      observer.complete();
    });
  }

  /*
  * @desc check if uploaded icon is valid before insert into database and save
  * @param file -> uploaded icon
  * @return boolean, true if valid, false otherwise
  */
  beforeUploadIcon = (file: UploadFile) => {
    return new Observable((observer: Observer<boolean>) => {
      // CHECK EXTENSION TYPES
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isJPG && !isPNG) {
        this.message.create('error', 'Only .png and .jpg files are supported', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      // CHECK SIZE file max size -> 2MB
      const isLt2M = file.size < 2097152;
      if (!isLt2M) {
        this.message.create('error', 'File size must be under 2MB', { nzDuration: 5000 });
        observer.complete();
        return;
      }
      // CHECK DIMENSION
      this.checkIconDimension(file, 512, 512).then(dimensionRes => {
        if (!dimensionRes) {
          this.message.create('error', 'File dimension must be 512x512', { nzDuration: 5000 });
          observer.complete();
          return;
        }
        observer.next((isJPG || isPNG) && isLt2M && dimensionRes);
        observer.complete();
      });
    });
  }
  checkIconDimension(file: UploadFile, maxW: number, maxH: number): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src!);
        resolve(width === maxW && height === maxH);
      };
    });
  }

  /*
  * @desc dinamically add a param's row while edit
  * @param par -> "param1"||"param2"||"param3"||"param4"
  * @return void
  */
  addParRow(par: Param[]) {
    if (par.length < 5) {
      par.push({ key: '', active: false, multiplier: '', duration: '' });
    }
  }

  /*
  * @desc dinamically remove a param's row while edit
  * @param par -> "param1"||"param2"||"param3"||"param4"
  * @param index -> par's row index
  * @return void
  */
  deleteParRow(par: Param[], index: number) {
    par.splice(index, 1);
    console.log(par);
  }
}

