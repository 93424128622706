import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { NzMessageService } from "ng-zorro-antd";
import { GlobalService } from "src/app/shared/global.service";
import * as XLSX from "xlsx";

@Component({
	selector: "app-users",
	templateUrl: "./users.component.html",
	styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
	sortName: string | null = null;
	sortValue: string | null = null;
	searchEmail = "";
	visibleSearch = false;
	dataResult: User[];
	sortedData: User[];
	displayedData: User[];
	listOfSearchName: string[] = [];
	loading = true;
	constructor(
		private dataService: DataService,
		private message: NzMessageService,
		private globalService: GlobalService
	) {}
	/*
	 * Get table content on Component init
	 */
	ngOnInit() {
		this.loading = true;
		this.dataService.getTablePiece("1").subscribe(
			(result1) => {
				this.dataService.getTablePiece("2").subscribe(
					(result2) => {
						this.dataResult = (<User[]>result1).concat(<User[]>result2);

						this.displayedData = [...this.dataResult];
						this.sortedData = [...this.displayedData];
						this.loading = false;
					},
					(error) => {
						this.message.create("error", "A backend error occured", { nzDuration: 5000 });
						this.loading = false;
						console.log("GET TABLE: ", error);
					}
				);
			},
			(error) => {
				this.message.create("error", "A backend error occured", { nzDuration: 5000 });
				this.loading = false;
				console.log("GET TABLE: ", error);
			}
		);
	}

	/*
	 * @desc assign to this.sortName and this.sortValue the inputed name and value
	 * @param -
	 * @return void
	 */
	sort(sort: { key: string; value: string }): void {
		this.sortName = sort.key;
		this.sortValue = sort.value;
		this.search();
	}

	/*
	 * @desc sort and filt displayed data from name and value
	 * @param sort -> contains selected key and value
	 * @return void
	 */
	search(): void {
		/* filter data */
		const filterFunc = (item: User) =>
			this.listOfSearchName.length ? this.listOfSearchName.some((email) => item.email.indexOf(email) !== -1) : true;
		const data = this.displayedData.filter((item) => filterFunc(item));
		/* sort data */
		if (this.sortName && this.sortValue) {
			this.displayedData = data.sort((a, b) =>
				this.sortValue === "ascend"
					? a[this.sortName!] > b[this.sortName!]
						? 1
						: -1
					: b[this.sortName!] > a[this.sortName!]
					? 1
					: -1
			);
		} else {
			this.displayedData = data;
		}
	}

	/*
	 * @desc change state action. Find and change record's status in the database
	 * @param code -> dataResult item's code
	 * @return void
	 */
	async onClickSwitch(email: string) {
		const index = this.dataResult.findIndex((item) => item.email === email);
		this.dataResult[index].su = !this.dataResult[index].su;

		const checkSumKey = await this.globalService.encryptDataGlobal(email + "TblUsers");
		const formData = new FormData();
		formData.append("table", "TblUsers");
		formData.append("code", email);
		formData.append("checkKey", checkSumKey);
		this.dataService.editStatus(formData).subscribe(
			(result) => {
				this.message.create("success", "User changed successfully", { nzDuration: 5000 });
			},
			(error) => {
				this.message.create("error", "A backend error occured", { nzDuration: 5000 });
				console.log("CHANGE SWITCH: ", error);
			}
		);
	}

	async onVerified(email: string) {
		const index = this.dataResult.findIndex((item) => item.email === email);
		this.dataResult[index].verified = !this.dataResult[index].verified;

		const checkSumKey = await this.globalService.encryptDataGlobal(email + "TblUsers");
		const formData = new FormData();
		formData.append("table", "TblUsers");
		formData.append("code", email);
		formData.append("checkKey", checkSumKey);
		this.dataService.editVerified(formData).subscribe(
			(result) => {
				this.message.create("success", "User changed successfully", { nzDuration: 5000 });
			},
			(error) => {
				this.message.create("error", "A backend error occured", { nzDuration: 5000 });
				console.log("VERIFIED ERROR: ", error);
			}
		);
	}

	reset(): void {
		this.searchEmail = "";
		this.find();
	}

	find(): void {
		this.visibleSearch = false;
		this.displayedData = this.dataResult.filter((item: User) => item.email.indexOf(this.searchEmail) !== -1);
	}

	downloadFile() {
		let excelData = this.dataResult;
		for (let i = 0; i < excelData.length; i++) {
			delete excelData[i].tokenTemp;
			delete excelData[i].devices;
		}
		console.log(excelData);
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
		const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
		XLSX.writeFile(workbook, this.toExportFileName("users"));
	}

	toExportFileName(excelFileName: string): string {
		return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
	}
}
